<template>
  <div>
    <div class="header">
      <van-nav-bar
        title="银行信息"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent" style="background: #181e34;">
		<div class="nav_wrap">
			<div class="nav_item" :class="{active_nav:ordertype==1}" @click="selorder(1)">银行卡</div>
			<div class="nav_item" :class="{active_nav:ordertype==2}" @click="selorder(2)">USDT</div>
		</div>
		<van-row>
			<van-list
			  v-if="orders.length"
			  v-model="loading"
			  :finished="finished"
			  :finished-text="$t('common.nomore')"
			>
			  <van-row
			    class="currency-Increase"
			    v-for="item in orders"
				v-if="item.type==ordertype"
			    :key="item.id"
			  >
			    <div class="info_item">
					<div class="tableTr">
						<span class="tableTd "> {{item.type==1?'姓名':'链名称'}}:</span>
						<span class="tableTd">{{item.type==1?item.name:item.bankname}}</span>
					</div>
					<div class="tableTr">
						<span class="tableTd">{{item.type==1?'银行':'USDT'}}账号:</span>
						<span class="tableTd">{{maskString(item.bankaccntno)}}</span>
					</div>
				</div>
				<van-button type="primary" style="width: 90%;margin: 30px auto;background: #ff571e;border: none;" 
					@click="cancelcard">
					取消{{ordertype==1?'银行卡':'USDT'}}
				</van-button>
			  </van-row>
			</van-list>
			<div v-else style="padding-top: 30px;">
				<div>
					<i class="icon-shuaqiaqiapianyinhangqia iconfont111"></i>
				</div>
				<div style="color: #8686aa;padding: 30px 0 0 10px;">请添加收款方式</div>
				<van-button type="primary" style="width: 90%;margin: 30px auto;background: #ff571e;border: none;" @click="$router.push('/addbankcard/'+ordertype)">添加{{ordertype==1?'银行卡':'USDT'}}</van-button>
			</div>
		</van-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orders: [],
      recharges: [],
      withdraws: [],
      transfers: [],
      finished: true,
      loading: false,
	  ordertype:1,
    }
  },
  created() {
    /* this.getRecharges()
    this.getTransfers() */
	this.getOrders();
  },
  mounted() {},
  methods: {
	  todetail(item){
		this.$router.push("/orderdetail/"+item.id);  
	  },
	  selorder(type){
		this.ordertype=type;  
		this.getOrders();
	  },
	 async cancelcard() {
      const { data } = await this.$http.get('/home/user/delbank/type/'+this.ordertype)
      if (data) {
        if (data.code === 200) {
			this.$toast.success('解除成功')
          this.getOrders();
        }else{
			this.$toast.fail(data.msg)
		}
      }
    },
    // 创新记录
    async getOrders() {
      const { data } = await this.$http.get('/home/user/getbanklist/type/'+this.ordertype)
      if (data) {
        if (data.code === 200) {
          this.orders = data.data
        }
      }
    },
    maskString(str) {
    	  let input=str+'';
    	  // 如果字符串长度小于等于6，直接返回原字符串
    	  if (input.length <= 6) {
    		  return '*';
    	  }
    
    	  const firstThree = input.slice(0, 3); // 获取前3个字符
    	  const lastThree = input.slice(-3);    // 获取后3个字符
    	  const stars = '*'.repeat(input.length - 6); // 获取中间部分用*替代的字符数
    
    	  return firstThree + stars + lastThree; // 拼接
    },
  }
}
</script>
<style lang="less" scoped>
	.info_item{
		color: #cad0e6;
		border: 0.026667rem solid #8686aa;
		width: calc(100% - 0.893333rem);
		margin: auto;
		margin-top: 2.666667rem;
		padding: 0.513333rem 0.4rem;
		border-radius: 0.513333rem;
	}
	.tableTr {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 0.026667rem solid #8686aa;
		min-height: 2.7rem;
		line-height: 2.7rem;
		font-size: 1rem;
	}
	.tableTr:last-child {
		border-bottom: none;
	}
	.tableTd:first-child {
		width: 30%;
		min-width: 30%;
		word-break: break-all;
		border: none;
		text-align: left;
	}
	.tableTd:last-child {
		width: 70%;
		min-width: 70%;
		word-break: break-all;
		    border: none;
			text-align: right;
	}
	.icon-shuaqiaqiapianyinhangqia{
		color: #ff571e;
		font-size: 8rem;
	}
	.red_txt{
		color: #e75671;
	}
	.green_txt{
		color: #28be86;
	}
	.nav_wrap{
		display: flex;
		background-color: #ff571e;
		.nav_item{
			width: 50%;
			padding: 20px 0;
			text-align: center;
			color: #cad0e6;
		}
		.active_nav{
			color: #ff571e;
			background-color: #181e34;
			border-radius: 0.5rem 0.5rem 0 0;
		}
	}
.maincontent {
  padding: 46px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #fff;
  height: 100vh;
  /deep/.van-tab {
    font-size: 1.04rem;
  }
  .currency-Increase {
    color: #8686aa;
    line-height: 1.6rem;
    font-size: 0.8rem;
    .currency-time {
      text-align: right;
    }
  }
}
</style>
