<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('tix.title')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent" style="background: #181e34;">
		<div class="nav_wrap">
			<div class="nav_item" :class="{active_nav:ordertype==1}" @click="selorder(1)">银行卡</div>
			<div class="nav_item" :class="{active_nav:ordertype==2}" @click="selorder(2)">USDT</div>
		</div>
		<van-row>
			<select id="unitDe" type="text" v-model="curmoneytype" v-if="ordertype==1">
				<option value="">请选择货币单位</option>
				<option :value="item.id" v-for="(item,index) in moneytype" :key="index">
					{{item.bieming}}
				</option>
			</select>
			<div class="form_cell_item">
			  <div class="form_cell_label">账户余额:</div>
			  <div class="ipt_wrap">{{formatNumberWithCommas(balance)}}</div>
			</div>
			<div class="form_cell_item">
			  <div class="form_cell_label">提款金额:</div>
			  <input placeholder="输入提款金额" v-model="txform.money"/>
			</div>
			<p class="ipt_tips" v-if="txform.money">≈{{realmoney}}
				<span v-if="ordertype==1">{{getcurmoneytype.danwei||'¥'}}</span>
				<span v-if="ordertype==2">$</span>
			</p>
			<div class="form_cell_item">
			  <div class="form_cell_label">手续费:</div>
			  <div class="ipt_wrap" v-if="config.withdrawl_fee">{{config.withdrawl_fee*100}}%</div>
			</div>
			<div class="form_cell_item">
			  <div class="form_cell_label">到账金额:</div>
			  <div class="ipt_wrap">{{((1 - config.withdrawl_fee)*txform.money).toFixed(2)}}</div>
			</div>
			<p class="ipt_tips" v-if="txform.money">≈{{getmoney}}
				<span v-if="ordertype==1">{{getcurmoneytype.danwei||'¥'}}</span>
				<span v-if="ordertype==2">$</span>
			</p>
			<template v-if="ordertype==1">
				<div class="form_cell_item">
				  <div class="form_cell_label">银行账号:</div>
				  <div class="ipt_wrap">{{maskString(getbankinfo.bankaccntno||'')}}</div>
				</div>
				<div class="form_cell_item">
				  <div class="form_cell_label">姓名:</div>
				  <div class="ipt_wrap">{{getbankinfo.name||'-'}}</div>
				</div>
			</template>
			<template v-if="ordertype==2">
				<div class="form_cell_item">
				  <div class="form_cell_label">收款链接:</div>
				  <div class="ipt_wrap">{{maskString(getbankinfo.bankaccntno||'')}}</div>
				</div>
				<div class="form_cell_item">
				  <div class="form_cell_label">链名称:</div>
				  <div class="ipt_wrap">{{getbankinfo.bankname||'-'}}</div>
				</div>
			</template>
			<div class="form_cell_item">
			  <div class="form_cell_label">提现密码:</div>
			  <input placeholder="输入提现密码" type="password" v-model="txform.txpass"/>
			</div>
			<van-button type="primary" 
			:disabled="iscanlogin"
			style="width: 90%;margin: 30px auto;background: #ff571e;border: none;" 
				@click="saveorder">
				提交
			</van-button>
		</van-row>
    </div>
	<tabbar/>
  </div>
</template>

<script>
	import tabbar from '../../components/tabbar.vue';
export default {
	components:{tabbar},
  data() {
    return {
		curmoneytype:"1",
		moneytype:[],
        loading: false,
	    ordertype:1,
		txform:{
			money:"",
			txpass:""
		},
		  balance: 0,
		  config: {},
		  banklist:[],
		  sellurl:1
    }
  },
  computed:{
	  getbankinfo(){
		if(this.banklist.length==0){
			return {}
		}else{
			let result = this.banklist.filter(item=>{return item.type==this.ordertype});
			if(result.length!=0){
				return result[0];
			}else{
				return {}
			}
		}
	  },
		iscanlogin(){
			return this.txform.money==''||this.txform.password==''
		},
	  realmoney(){
		  let huilv=this.getcurmoneytype.huilv?this.getcurmoneytype.huilv:1;
		if(this.ordertype==2){
			huilv=this.sellurl?+this.sellurl:1;
		}
		  if(huilv){
		  	if(this.txform.money){
		  		return (+this.txform.money/huilv).toFixed(2);
		  	}else{
		  		return '0.00';
		  	}
		  }else{
		  	return '0.00';
		  }
	  },
	  getmoney(){
		let huilv=this.getcurmoneytype.huilv?this.getcurmoneytype.huilv:1;
		if(this.ordertype==2){
			huilv=this.sellurl?+this.sellurl:1;
		}
		if(huilv){
			if(this.txform.money){
				let money = ((1 - this.config.withdrawl_fee)*this.txform.money);
				return (+money/huilv).toFixed(2);
			}else{
				return '0.00';
			}
		}else{
			return '0.00';
		}
	  },
	getcurmoneytype(){
		if(this.curmoneytype){
			let arr=this.moneytype.filter(item=>{return item.id==this.curmoneytype});
			if(arr.length!=0){
				return arr[0];
			}else{
				return {};
			}
		}else{
			return {};
		}
		
	}  
  },
  created() {
	  this.getOrders();
    this.getcointype();
	this.getinfo();
	this.getdata();
  },
  mounted() {},
  methods: {
	  async getdata() {
	    const { data } = await this.$http.get('/home/home/index')
	    if (data) {
	      if (data.code === 200) {
	        this.sellurl = data.data.sellurl
	      }
	    }
	  },
    async getinfo() {
      const { data } = await this.$http.get('/home/user/withdraw')
      if (data) {
        if (data.code === 200) {
          this.balance = data.data.balance
          this.config = data.data.config
          if (this.config.withdrawl_opend === '0') {
            this.$toast.fail(this.$t('tix.erroropen'))
          }
          if (!this.config.attime) {
            this.$toast.fail(
              this.$t('tix.errortime') + this.config.withdrawl_times
            )
          }
        }
      }
    },
	  async saveorder(){
		  if (!this.getbankinfo.bankaccntno) {
		    this.$toast.fail('请先添加银行卡');
		    return false
		  }
		  if ( !this.txform.money) {
		    this.$toast.fail(this.$t('tix.complate'))
		    return false
		  }
		  if(!this.txform.txpass){
			  this.$toast.fail('请输入提现密码')
			  return false
		  }
		  if(this.ordertype==1){
			  if(!this.getcurmoneytype.id){
				  this.$toast.fail('请选择币种')
				  return false
			  }
		  }
		  let bankinfo= this.getbankinfo.name+'-'+this.getbankinfo.bankname+'-'+this.getbankinfo.kaihuhang;
		  const postform = {
			  ordertype:this.ordertype,
		    product_type:  this.ordertype==1?this.getcurmoneytype.bieming:'USDT',
		    product_type_id: this.ordertype==1?this.getcurmoneytype.id:1,
		    withdraw_url: this.getbankinfo.bankaccntno,
		    withdraw_num: this.txform.money,
		    service_charge: this.config.withdrawl_fee,
			txpass:this.txform.txpass,
			bankid:this.getbankinfo.id,
			bankinfo:bankinfo,
			bizhong_num:this.getmoney,
			bizhong_num1:this.realmoney
		  }
		  const { data } = await this.$http.post('/home/user/withdraw', postform)
		  if (data) {
		    if (data.code === 200) {
		      this.$toast.success(this.$t('tix.submitok'))
		      this.txform = {
		        money:"",
		        txpass:""
		      }
		      this.getinfo()
		    } else {
		      this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
		    }
		  }
	  },
	  todetail(item){
		this.$router.push("/orderdetail/"+item.id);  
	  },
	  selorder(type){
		this.ordertype=type;  
		this.getOrders();
	  },
	  async getcointype(){
		  const { data } = await this.$http.get('/home/trade/gethuobi')
		  if (data) {
		    if (data.code === 200) {
		      this.moneytype = data.data
			  let moren = this.moneytype.filter((item)=>{
			  	return item.moren==1;
			  });
			  if(moren.length>0){
			  	this.curmoneytype=moren[0].id;
			  }else{
			  	this.curmoneytype=this.moneytype[0].id;
			  }
		    }
		  }
	  },
    async getOrders() {
      const { data } = await this.$http.get('/home/user/getbanklist/type/'+this.ordertype);
      if (data) {
        if (data.code === 200) {
          this.banklist = data.data
        }
      }
    },
  formatNumberWithCommas(number) {
	 if (typeof number !== "number") {
	     number = parseFloat(number);
	     if (isNaN(number)) {
	       throw new Error("Invalid input: Not a valid number");
	     }
	   }
	 
	   // Split the number into integer and decimal parts
	   const [integerPart, decimalPart] = number.toString().split(".");
	   
	   // Add commas to the integer part
	   const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	 
	   // Combine the integer and decimal parts
	   return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
  },
  maskString(str) {
	  let input=str+'';
	  // 如果字符串长度小于等于6，直接返回原字符串
	  if (input.length <= 6) {
		  return '*';
	  }
  
	  const firstThree = input.slice(0, 3); // 获取前3个字符
	  const lastThree = input.slice(-3);    // 获取后3个字符
	  const stars = '*'.repeat(input.length - 6); // 获取中间部分用*替代的字符数
  
	  return firstThree + stars + lastThree; // 拼接
  },
  }
}
</script>
<style lang="less" scoped>
	.ipt_tips{
		text-align: left;
		padding-left: calc(135px + 0.6rem);
		color: #8686aa;
	}
		.form_cell_item{
			padding: 0.6rem;
			display: flex;
			align-items: center;
			position: relative;
			&::after {
			    content: " ";
			    position: absolute;
			    left: 0;
			    bottom: 0;
			    right: 0;
			    height: 0.026667rem;
			    border-bottom: 0.026667rem solid #e5e5e5;
			    color: #e5e5e5;
			    -webkit-transform-origin: 0 100%;
			    -ms-transform-origin: 0 100%;
			    transform-origin: 0 100%;
			    -webkit-transform: scaleY(0.5);
			    -ms-transform: scaleY(0.5);
			    transform: scaleY(0.5);
			    z-index: 2;
			}
			.form_cell_label{
				width: 115px;
				font-size: 18px;
				color: #8686aa;
				text-align: left;
			}
			input,.ipt_wrap{
				text-align: left;
				width: 100%;
				flex: 1;
				padding:10px 20px;
				box-sizing: border-box;
				background-color: transparent;
				color: #8686aa;
				outline: none;
				font-size: 18px;
				border: none;
			}
		}
	#unitDe{
		border: 0.026667rem solid #ff571e;
		height: 3.5rem;
		box-sizing: border-box;
		display: block;
		width: 95%;
		color: #ff571e;
		border-radius: 0.613333rem;
		padding: 0.613333rem;
		margin: 0.413333rem;
		outline: 0;
		-webkit-appearance: none;
		background-color: transparent;
	}
	.info_item{
		color: #cad0e6;
		border: 0.026667rem solid #8686aa;
		width: calc(100% - 0.893333rem);
		margin: auto;
		margin-top: 2.666667rem;
		padding: 0.513333rem 0.4rem;
		border-radius: 0.513333rem;
	}
	
	.red_txt{
		color: #e75671;
	}
	.green_txt{
		color: #28be86;
	}
	.nav_wrap{
		display: flex;
		background-color: #ff571e;
		.nav_item{
			width: 50%;
			padding: 20px 0;
			text-align: center;
			color: #cad0e6;
		}
		.active_nav{
			color: #ff571e;
			background-color: #181e34;
			border-radius: 0.5rem 0.5rem 0 0;
		}
	}
.maincontent {
  padding: 46px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #fff;
  height: 100vh;
}
</style>
