<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
	export default {
		data(){
			return {
				
			}
		},
		mounted() {
			this.getdata();
		},
		methods:{
			async getdata() {
			  const { data } = await this.$http.get('/home/index/serviceurl')
			  if (data) {
			    if (data.code === 200) {
					if(data.sitename){
						document.title=data.sitename;
					}
			    }
			  }
			},
		}
	}
</script>
