<template>
  <div class="first">
    <div class="header" v-if="false">
      <van-nav-bar>
        <template #left>
          <van-image
            width="2rem"
            height="2rem"
            :src="require('../../assets/img/btc.png')"
            @click="openside"
          />
        </template>
        <template #title>
          <van-search
            v-model="search"
            clearable
            shape="round"
            :placeholder="$t('home.search')"
          />
        </template>
      <!--  <template #right>
          <van-icon
            name="chat-o"
            :badge="msgcount"
            @click="$router.push('/trade/contact/list')"
            v-if="msgcount > 0"
          />
          <van-icon
            name="chat-o"
            @click="$router.push('/trade/contact/list')"
            v-else
          />
        </template> -->
      </van-nav-bar>
    </div>
    <div class="maincontent">
      <!-- 轮播图 -->
      <div class="swipe">
        <van-swipe
          class="first-swipe"
          :autoplay="3000"
        >
          <van-swipe-item>
            <img :src="require('../../assets/img/swipe.png')" />
          </van-swipe-item>
          <van-swipe-item>
            <img :src="require('../../assets/img/swipe1.png')" />
          </van-swipe-item>
          <van-swipe-item>
            <img :src="require('../../assets/img/swipe2.png')" />
          </van-swipe-item>
          <template #indicator>
            <span></span>
          </template>
        </van-swipe>
      </div>
      <!-- 通知栏 -->
      <van-notice-bar
        scrollable
		 color="#e75671"
        left-icon="volume-o"
        :text="lang === 'zh' ? indexData.notice[0] : indexData['notice_' + lang ][0]"
      />
      <!-- 三个产品 -->
      <div class="currency-information" v-if="false">
        <div
          class="information skiptranslate"
          v-for="item in product"
          :key="item.pid"
          @click="totrade(item.pid)"
        >
          <span style="color: black;">
            <b style="margin-right: 10px;">{{ item.product_title }}</b>
            <span :style="{ color: item.change > 0 ? '#85b480' : '#b34f59' }">{{ item.change }}%</span><br />
            <span style="color: rgb(179, 79, 89); font-size: 25px;"><b>{{ item.current }}</b></span>
          </span>
        </div>
      </div>
      <!-- 快捷买币 -->
      <van-row class="currency" type="flex" gutter="10">
          <van-col
            span="14"
          >
			<van-row
			  class="currency-shopping"
			  @click="$router.push('/assets/recharge')"
			>
			  <van-col
				span="18"
				class="currency-shopping-left"
			  >
				<b>{{ $t('home.quickbuy') }}</b>
				<br />
				<span style="color: #8686aa;">{{ $t('home.quicktext') }}</span>
			  </van-col>
			  <van-col
				span="4"
				class="currency-shopping-image"
			  >
				<i class="iconfont11 icon-drxx89 mr ficonStyle fontMain"></i>
			  </van-col>
			</van-row>
          </van-col>
          <van-col
            span="10"
          >
				<van-row
				  class="currency-service"
				   gutter="10"
				>
				  <van-col
					span="24"
					class="currency-service-span"
					style="padding-right: 0.666667px;"
				  >
					<div @click="toservice">
					  <i class="iconfont111 icon-zaixiankefu3 iconStyle pr mr fontMain"></i>
					  <span>{{ $t('home.serverurl') }}</span>
					</div>
				  </van-col>
				  <van-col
					span="24"
					class="currency-service-span"
					style="padding-left: 0.333333px; padding-right: 0.333333px;margin-top: 10px;"
				  >
					<div @click="tolixibao">
					  <i class="iconfont111 icon-yuebao iconStyle pr mr fontMain"></i>
					  <span>利息宝</span>
					</div>
				  </van-col>
				  <van-col
					span="24"
					v-if="false"
					class="currency-service-span"
					style="padding-left: 0.666667px; border-left: 1px solid rgb(243, 243, 243);"
				  >
					<div @click="$router.push('/trade/contact/list')">
					  <van-image
						width="2rem"
						height="2rem"
						:src="require('../../assets/img/kftb.png')"
					  />
					  <span>{{ $t('home.fund') }}</span>
					</div>
				  </van-col>
				</van-row>
          </van-col>
      </van-row>
      <!-- banner -->
      <van-row class="banner-image" v-if="false">
        <van-image :src="require('../../assets/img/hb-banner.png')" />
      </van-row>
      <!-- 产品列表 -->
<van-tabs v-model="active" title-active-color="#ff571e" title-inactive-color="#cad0e6">
  <van-tab :title="$t('trade.title3')">
      <van-row style="margin: 0px auto; background-color: #181e34; width: 100%;">
        <van-row class="currency-title">
          <van-col span="8"><span>{{ $t('home.hq') }}</span></van-col>
          <van-col span="8"><span>{{ $t('home.price') }}</span></van-col>
          <van-col span="8"><span>{{ $t('home.updown') }}</span></van-col>
        </van-row>
        <van-row style="background-color: #181e34; width: 100%; margin: 0px auto;">
          <ul>
            <van-row
              v-if="item.product_type_id==2"
              class="currency-Increase skiptranslate"
              v-for="item in product"
              :key="item.id"
            >
              <van-col span="8"><span>{{ item.product_title }}</span></van-col>
              <van-col span="8"><span style="color:#8686aa">{{ item.current }}</span></van-col>
              <van-col span="8">
                <van-button
                  class="currency-btn"
                  :style="{
                    backgroundColor: item.change > 0 ? '#02ac8f' : '#e9506c'
                  }"
                  @click="totrade(item.pid)"
                >
                  {{ item.change }}%
                </van-button>
              </van-col>
            </van-row>
            <van-row style="margin:20px">
              <span>{{ $t('common.nomore') }}</span>
            </van-row>
          </ul>
        </van-row>
      </van-row>
  </van-tab>
	<van-tab title="USDT">
	    <van-row style="margin: 0px auto; background-color: #181e34; width: 100%;">
	      <van-row class="currency-title" style="text-align: left;">
	        <van-col span="8" style="padding-left: 0.5rem;"><span>{{ $t('home.hq') }}</span></van-col>
	        <van-col span="8"><span>状态</span></van-col>
	        <van-col span="8"><span>{{ $t('home.price') }}</span></van-col>
	      </van-row>
	      <van-row style="background-color: #181e34; width: 100%; margin: 0px auto;margin-top: 20px;">
	        <ul>
	          <van-row
	            v-if="item.product_type_id==1"
	            class="currency-Increase skiptranslate"
	            v-for="item in product"
	            :key="item.id"
	                @click="totrade(item.pid)"
				 style="text-align: left;"
	          >
	            <van-col span="8"style="padding-left: 0.5rem;">
					<div style="display: flex;align-items: center;">
						<img :src="getlogo(item)" style="width: 42px;height: 42px;border-radius: 50%;"/>
						<span style="font-size: 0.75rem;color: #cad0e6;margin-left: 0.3rem;">{{ item.product_title }}</span>
					</div>
				</van-col>
	            <van-col span="8"><span style="color:#8686aa;font-size: 0.8rem;">交易中</span></van-col>
	            <van-col span="8">
	              <van-button
	                class="currency-btn"
	                :style="{
	                  backgroundColor: item.change > 0 ? '#02ac8f' : '#e9506c'
	                }"
	              >
	                {{ item.current }}
	              </van-button>
	            </van-col>
	          </van-row>
	          <van-row style="margin:20px">
	            <span>{{ $t('common.nomore') }}</span>
	          </van-row>
	        </ul>
	      </van-row>
	    </van-row>
	</van-tab>
</van-tabs>


      <!-- 弹出层 -->
      <van-popup
        v-model="popshow"
        position="left"
        style="height:100%; width:80%"
      >
        <!-- 头部 -->
        <van-row class="personage-head">
          <van-row class="personage-head-image">
            <van-image
              width="5rem"
              height="5rem"
              :src="require('../../assets/img/btc.png')"
            />
          </van-row>
          <van-row class="personage-head-span">
            <van-row @click="tosetting">
              <b v-if="user.nickname">{{ user.nickname }}</b>
              <b v-else>{{ $t('home.nickname') }}</b>
            </van-row>
            <van-row>
              <span>UID:{{ user.username }}</span>
            </van-row>
          </van-row>
        </van-row>
        <!-- vip -->
        <van-row class="personage-member">
          <van-row
            class="personage-member-a"
            @click="toservice"
          >
            <van-col
              span="5"
              style="margin-top: 5px;"
            >
              <van-image
                width="3rem"
                height="2.5rem"
                :src="require('../../assets/img/vip.png')"
              />
            </van-col>
            <van-col span="13"><span style="font-size: 15px; color: rgb(146, 116, 47);">{{
                $t('home.vip')
              }}</span></van-col>
            <van-col>
              <van-image
                width="3rem"
                height="2rem"
                style="width: 0.8rem; height: 1.5rem; margin-top: 3px;"
                :src="require('../../assets/img/vip-1.png')"
              />
            </van-col>
          </van-row>
        </van-row>
        <!-- 列表 -->
        <van-row>
          <van-cell
            icon="user-circle-o"
            :title="$t('home.sf_auth')"
            is-link
            to="/person/authentication"
          />
          <van-cell
            icon="smile-comment-o"
            :title="$t('home.gj_auth')"
            is-link
            to="/person/authentication"
          />
          <van-cell
            icon="service-o"
            :title="$t('home.serverurl')"
            is-link
            :url="serverurl"
          />
          <van-cell
            icon="question-o"
            :title="$t('home.help')"
            is-link
            to="/course"
          />
          <van-cell
            icon="setting-o"
            :title="$t('home.set')"
            is-link
            to="/person/setting"
          />
        </van-row>
        <!-- 切换语言 -->
        <!-- <van-row
          class="personage-cut"
        > -->
          <!-- <span> {{ $t('home.change') }} </span>
          <i
            class="van-icon van-icon-arrow"
            style="margin-left: 5px; color: rgb(0, 78, 163);"
          ></i> -->
        <!-- </van-row> -->
        <van-dropdown-menu direction='up'>
            <van-dropdown-item :title="$t('qie-huan-yu-yan')" v-model="selectLang" :options="selectLangOption" @change="selectLangChange" />
        </van-dropdown-menu>
        <!-- 退出登录 -->
        <van-row
          class="personage-button"
          @click="logout"
        ><b>{{ $t('home.logout') }}</b></van-row>
      </van-popup>
      <van-popup
        v-model="suiji"
        position="center"
        :close-on-click-overlay="false"
        style="width:80%;border-radius:8px; font-size:16px"
      >
        <h3 style="line-height:40px">{{$t('editpass.title')}}</h3>
        <van-cell-group>
          <van-field
            v-model="editpass.oldpass"
            center
            clearable
            type="password"
            :placeholder="$t('register.enterpassword')"
          />
          <van-field
            v-model="editpass.newpass"
            center
            clearable
            type="password"
            :placeholder="$t('qing-shu-ru-xin-mi-ma')"
          />
          <van-field
            v-model="editpass.newpass2"
            center
            clearable
            type="password"
            :placeholder="$t('qing-shu-ru-xin-mi-ma')"
          />
        </van-cell-group>
        <van-button
          style="width:100%"
          @click="passsubmit"
        >{{$t('common.confirm')}}</van-button>
      </van-popup>
    </div>
    <audio
      controls
      ref="notify"
      style="display:none"
    >
      <source src="msg.mp3" />
    </audio>
    <van-notify
      v-model="notifyshow"
      @click.native="$router.push('/trade/contact/list')">
      <span>{{$t('ni-you') }}{{ msgcount }}{{ $t('tiao-xin-xiao-xi') }}</span>
      <van-icon
        name="cross"
        style="position:absolute;right:1rem;"
        @click.stop="closenotify"
      />
    </van-notify>
  </div>
</template>
<script>
function setCookie(cname, cvalue, exdays) {
  var d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  var expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + '; ' + expires
}
setCookie('googtrans', '/auto/en', 100)
export default {
  data() {
    return {
      selectLang: '',
      selectLangOption: [
        { text: '中文', value: 'zh' },
        { text: 'English', value: 'en' },
        { text: 'русский язык', value: 'ru' },
        { text: 'Français', value: 'fr' },
        { text: 'Español', value: 'es' },
        { text: 'Italiano', value: 'it' }
      ],
      lang: localStorage.getItem('lang') || 'zh',
      search: '',
      popshow: false,
      indexData: {
        notice: ['']
      },
      product: [],
	  productlogo:[],
      serverurl: '',
      user: {},
      suiji: false,
      editpass: {
        oldpass: '',
        newpass: '',
        newpass2: ''
      },
      msgcount: 0,
      notifyshow: false,
      websocket: null,
      wk: null,
      active: 1,
    }
  },
  created() {
    this.getdata()
    this.getmsgcount()
    // alert(this.lang)
  },
  mounted() {
    this.initwk()
  },
  methods: {
	  tolixibao(){
		  this.$router.push('/invest')
	  },
	  getlogo(item){
		let result = this.productlogo.filter(item1=>{
			return item.product_title==item1.product_title
		});
		if(result.length>0){
			return result[0].logoimg
		}else{
			return ''
		}
	  },
    // 获取未读信息数
    async getmsgcount() {
      const { data } = await this.$http.get('/home/home/msgcount')
      if (data) {
        if (data.code === 200) {
          this.msgcount = +data.data
          if (this.msgcount > 0) {
            this.notifyshow = true
            this.$refs.notify.play()
          }
        }
      }
    },
    // 获取首页数据
    async getdata() {
      const { data } = await this.$http.get('/home/home/index')
      if (data) {
        if (data.code === 200) {
          this.indexData = data.data
          this.productlogo = data.data.product
          this.product = data.data.product
          this.serverurl = data.data.serverurl
          this.user = data.data.user
		  if(data.data.sitename){
			  document.title=data.data.sitename;
		  }
          if (this.websocket === null) {
            this.initwebsocket()
          }
          if (data.data.user.random_password === '1') {
            this.suiji = true
          }
        }
      }
    },
    openside() {
      this.popshow = true
    },
    // 去教程
    tocourse() {
      this.$router.push('/course')
    },
    // 去理财页
    tofund() {
      this.$router.push('/fund')
    },
    // 去设置页
    tosetting() {
      this.$router.push('/person/setting')
    },
    // 客服链接
    toservice() {
      window.location.href = this.serverurl
    },
    // 去交易页
    totrade(id) {
      this.$router.push('/trade_' + id)
    },
    // 退出
    logout() {
      window.localStorage.removeItem('token')
      this.$router.push('/login')
    },
    async passsubmit() {
      if (
        !this.editpass.oldpass ||
        !this.editpass.newpass ||
        !this.editpass.newpass2
      ) {
        this.$toast.fail(this.$t('editpass.notice3'))
        return false
      }
      if (this.editpass.newpass !== this.editpass.newpass2) {
        this.$toast.fail(this.$t('editpass.eqpass'))
        return false
      }
      const { data } = await this.$http.post(
        '/home/home/editpass',
        this.editpass
      )
      if (data) {
        if (data.code === 200) {
          this.suiji = false
          this.getdata()
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
        }
      }
    },
    selectLangChange(value) {
      this.$i18n.locale = value
      localStorage.setItem('lang', value)
    },
    // 切换语言
    Language() {
      // function setCookie(cname, cvalue, exdays) {
      //   var d = new Date()
      //   d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
      //   var expires = 'expires=' + d.toUTCString()
      //   document.cookie = cname + '=' + cvalue + '; ' + expires
      // }
      // setCookie('googtrans', '', -1111111111111)
      setCookie('googtrans', '/auto/en', 100)
      if (this.$i18n.locale === 'zh') {
        this.$i18n.locale = 'en'
        localStorage.setItem('lang', 'en')
        // setCookie('googtrans', '/auto/en', 100)
      } else {
        this.$i18n.locale = 'zh'
        localStorage.setItem('lang', 'zh')
        // setCookie('googtrans', '/auto/zh', 100)
      }
      window.location.reload()
    },
    // 初始化连接
    initwebsocket() {
      this.websocket = new WebSocket(this.wsurl + '/wss:8880')
      this.websocket.onmessage = this.websocketonmessage
      this.websocket.onerror = this.websocketonerror
    },
    // 连接错误
    websocketonerror() {
      this.initwebsocket()
    },
    // 接收到数据
    websocketonmessage(e) {
      const message = JSON.parse(e.data)
      switch (message.type) {
        case 'init':
          this.binduid()
          break
        case 'text':
          this.getmsgcount()
          break
        case 'image':
          this.getmsgcount()
          break
      }
    },
    // 绑定uid
    binduid() {
      const msg = { type: 'bind', userid: this.user.id }
      this.websocketsend(JSON.stringify(msg))
    },
    // websorcket发送信息
    websocketsend(data) {
      this.websocket.send(data)
    },
    closenotify() {
      this.notifyshow = false
    },
    initwk() {
      this.wk = new WebSocket(this.wsurl + '/wssss:8080')
      this.wk.onmessage = this.wkonmessage
    },
    wkonmessage(e) {
      try {
        const data = JSON.parse(e.data)
        // console.log('行情:', data)
        // console.log(this.product)
        for (let key in data) {
          data[key].pid = this.product[key].pid
        }
        this.product = data
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
 /deep/ .van-tabs__nav,.van-tabs{
	  background-color: #181e34;
  }
  /deep/ .van-tabs__wrap{
	  display: none;
  }
.header {
  width: 100%;
  .van-search__content--round {
    height: 2rem;
  }
  /deep/.van-search {
    padding: 0;
  }
  /deep/.van-icon {
    color: #b2b2b2;
  }
  /deep/.van-nav-bar__right {
    font-size: 1.5rem;
  }
  .van-info {
    min-width: 18px;
    font-size: 14px;
  }
}
.ficonStyle {
    font-size:2.8rem;
	color: #ff571e;
}
.maincontent {
  padding: 50px 0;
  padding-top: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  // 轮播图
  .first-swipe {
    height: 11rem;
    .van-swipe-item img {
      width: 100%;
    }
  }
  .van-notice-bar {
    width: 100%;
    background-color: #181e34;
    color: #cad0e6;
	/deep/ .van-notice-bar__left-icon{
		color: #cad0e6 !important;
	}
  }
  //三个产品
  .currency-information {
    margin: 0.53333rem 0.8rem 0.53333rem 0.8rem;
    line-height: 2.66667rem;
    font-size: 0.93333rem;
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    .information {
      flex-shrink: 0;
      margin-right: 0.53333rem;
      width: 9.6rem;
      height: 4.26667rem;
      padding: 0.4rem;
      background-color: #fff;
      line-height: 1.6rem;
      border-radius: 0.26667rem;
    }
  }
  // 快捷买币
  .currency {
    width: 100%;
	padding: 10px;
	box-sizing: border-box;
    background: #131629;
    overflow: hidden;
    .currency-shopping {
		height: 100%;
      background-color: #181e34;
      color: #cad0e6;
      line-height: 1.46667rem;
      padding: 0.4rem;
      display: flex;
      align-items: center;
    border-radius: 0.26667rem;
      .currency-shopping-image {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .currency-shopping-left {
        font-size: 0.7rem;
        text-align: left;
        padding-left: 0.8rem;
        span {
          font-size: 0.71333rem;
        }
      }
    }
    .currency-service-span {
    border-radius: 0.26667rem;
      div {
        padding:0.8rem 0.4rem;
        background-color: #181e34;
		color: #cad0e6;
        display: flex;
        align-items: center;
        text-align: left;
		justify-content: center;
        .van-image {
          padding: 0;
        }
		.iconStyle {
			font-size: 1rem;
			color: #ff571e;
		}
        .van-col {
          float: left;
          box-sizing: border-box;
          min-height: 1px;
        }
        span {
          font-size: 0.8rem;
          margin-left: 0.53333rem;
        }
      }
    }
  }
  // banner
  .banner-image {
    width: 94%;
    margin: 0.53333rem 3%;
    .van-image {
      position: relative;
      display: inline-block;
    }
    /deep/.van-image__img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 0.53333rem !important;
    }
  }
  // 产品列表
  .currency-title {
    margin-top: 0.8rem;
    margin-bottom: 0.4rem;
    font-size: 0.8rem;
    color: #cad0e6;
  }
  .currency-Increase {
    background-color: #181e34;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    font-size: 1.12rem;
    border-bottom: 0.02667rem solid #23293f;
    display: flex;
    align-items: center;
	color: #fff;
    .currency-btn {
      border: none;
      border-radius: 0.56667rem;
      width: 6.4rem;
      height: 2.13333rem;
      color: #fff;
      font-size: 1rem;
    }
  }
}
// 弹出层
.personage-head {
  height: 18%;
  display: flex;
  align-items: center;
  justify-content: center;
  .personage-head-image {
    margin-right: 5%;
  }
  .personage-head-span {
    text-align: left;
    font-size: 1.33333rem;
    line-height: 2.13333rem;
  }
}
.personage-member {
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  .personage-member-a {
    height: 100%;
    width: 90%;
    padding: 0 5%;
    border-top-left-radius: 0.26667rem;
    border-top-right-radius: 0.26667rem;
    background-color: #e9c455;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .van-col {
    float: left;
    box-sizing: border-box;
    min-height: 1px;
  }
}
// 列表区域
.van-cell {
  font-size: 1.06667rem;
  color: #000 !important;
  height: 3.2rem;
  letter-spacing: 0.26667rem;
}
.van-popup .van-icon {
  font-size: 1.2rem;
}
.van-cell__title {
  text-align: left;
  margin-left: 0.4rem;
}
// 切换语言
.personage-cut {
  width: 100%;
  position: fixed;
  bottom: 4rem;
  background-color: #f7f7f7;
  height: 2.66667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.98667rem;
  color: #004ea3;
}
// 退出登录
.personage-button {
  width: 100%;
  position: fixed;
  bottom: 0.63333rem;
  b {
    font-size: 1.6rem;
    letter-spacing: 0.48rem;
  }
}
</style>
